import { Box, Typography, Button, IconButton, Checkbox } from '@mui/material'
import React, { useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import './ContactForm.css'
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import { useSnackbar } from 'notistack';
import { googleSheetEndpoint3, hideBodyOverflow, removeQueryData } from '../utils/utils';

let textFieldStyle={
    width:'100%',
}
export default function ContactForm({formIsOpen, closeForm, openPrivacy}) {
    
   
    
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [phoneError, setPhoneError] = React.useState(false);

    const handleClickVariant = (variant) => {
        enqueueSnackbar("Enregistré ! À bientôt !",{ variant,   autoHideDuration: 3000
        });
      };

      useEffect(() => {
        hideBodyOverflow(formIsOpen)
      }, [formIsOpen])
    function handleSubmit(e)
    {   
        const formElement = document.getElementById('form');
        e.preventDefault();
        if(email.length < 1)
        {
            setEmailError(true);
            return;
        }
        if(phoneNumber.length < 1)
        {
            setPhoneError(true);
            return;
        }
        const formData = new FormData(formElement);
        fetch(googleSheetEndpoint3, {
            method: 'POST',
            body: formData,
            crossDomain: true,
        })
        .then(response => {
            if (!response.ok) {
                // If the response is not ok, throw an error with the status text
                throw new Error('Network response was not ok: ' + response.statusText);
            }
            // Check if the content type is JSON before parsing
            const contentType = response.headers.get('content-type');
            if (!contentType || !contentType.includes('application/json')) {
                throw new Error('Expected JSON response, got: ' + contentType);
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            handleClickVariant('success');
            closeForm();
            removeQueryData();
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
            handleClickVariant('success');
            closeForm();
            removeQueryData();
        
    }

  return (
    formIsOpen && (
    <Box sx={{ paddingTop:'1rem', position:"fixed", height:'100vh', width:'100vw', bgcolor:'white', zIndex:'3000', display:'flex', alignItems:'center', flexDirection:'column'}} className="contact-form-container">
        <IconButton 
        sx={{
            position:{xs:'static', md:'absolute'}, 
            width:'40px', 
            top:'5%', 
            right:'5%', 
            height:'40px', 
            backgroundColor:'white',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            marginBottom:'1rem'
        }}
        size='small'
        variant="contained" 
        label="close"
        onClick={closeForm}
        >
            <CloseIcon id='close-icon' sx={{color:'black'}}/>
        </IconButton>
        <Box>
            <Typography variant="h4" color="text.tertiary" sx={{textAlign:'center', margin:'1rem 0rem 3rem 0rem' }}>S'inscrire</Typography>
        </Box>
        <Box className="form-container" sx={{width:{xs:'90%', md:'50%'}}}>
        <form id="form" style={{width:'100%', maxWidth:'100%'}} autoComplete="off" onSubmit={handleSubmit}>
            <FormControl>
                <TextField 
                    autoFocus={true}
                    label="Nom"
                    id='form-name'
                    placeholder='Tapez votre nom'
                    onChange={e => setName(e.target.value)}
                    required
                    variant="outlined"
                    color="primary"
                    size='small'
                    type="name"
                    name="name"
                    sx={{...textFieldStyle,mb: 3}}
                    fullWidth
                    autoComplete='off'
                    defaultValue={name}
                 />
                 <TextField 
                    label="Addresse email"
                    autoComplete='off'
                    placeholder='Laisse-nous ton email'
                    onChange={e => setEmail(e.target.value)}
                    required
                    name="email"
                    variant="outlined"
                    color="primary"
                    size='small'
                    type="email"
                    value={email}
                    error={emailError}
                    helperText="Tapez votre email"
                    fullWidth
                    sx={{mb: 3, color:'black'}}
                 />
                 <TextField 
                    label="Numéro de téléphone"
                    placeholder='Balance ton numéro!'
                    onChange={e => setPhoneNumber(e.target.value)}
                    required
                    name='phoneNumber'
                    variant="outlined"
                    color="primary"
                    size='small'
                    type="tel"
                    value={phoneNumber}
                    error={phoneError}
                    fullWidth
                    sx={{mb: 3}}
                 />
                 
                 <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <Checkbox id='form-checkbox' required/>
                    <p style={{fontSize:'10px'}}>J'accepte la <span href="#" style={{cursor:"pointer", color:'#00448A', fontWeight:'800', textDecoration:'underline'}} onClick={openPrivacy}>politique de confidentialité</span>.</p>
                </Box>
                 <Button sx={{bgcolor:'#007FFF', marginTop:'2rem'}} variant="contained" type="submit">Inscription</Button>
            </FormControl>
        </form>
        </Box>
    </Box>)
  )
}
