let googleSheetEndpoint3= "https://script.google.com/macros/s/AKfycbyvuh06T33pdiBbUbL0oGikA_qOzwIJQhNm5RnzoSUck0vatpRLz8YTedZzV46HvXigqQ/exec";
function hideBodyOverflow(privacyIsOpen){
    if (privacyIsOpen) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'unset'
      }
}
function closeDrawer(value){
    return value && !value
}

function removeQueryData(){
    window.history.pushState(null, "", window.location.href.split("?")[0]);
}

export { googleSheetEndpoint3, removeQueryData, hideBodyOverflow, closeDrawer }