import logo from './ppc.svg';
import bg from './background.png';
import loading from "./logo.svg"
import fbIcon from './fb-icon.svg'
import emailIcon from './email-icon.svg'
import './App.css';
import { Button, Typography } from '@mui/material';
import ContactForm from './Component/ContactForm';
import Privacy from './Component/Privacy';
import { useState } from 'react';
import { SnackbarProvider } from 'notistack';
function App() {
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [privacyIsOpen, setPrivacyIsOpen] = useState(false);

  function openContactForm(){
    setFormIsOpen(!formIsOpen);
  }

  function closePrivacy(){
    setFormIsOpen(true)
    setPrivacyIsOpen(false)
  }
  function openPrivacy(){
    setFormIsOpen(false)
    setPrivacyIsOpen(true);
  }

  return (
    <SnackbarProvider maxSnack={3}>

    <div className="App">
      <ContactForm formIsOpen = {formIsOpen} closeForm={openContactForm} openPrivacy={openPrivacy}/>
      <Privacy privacyIsOpen={privacyIsOpen} openPrivacy={openPrivacy} closePrivacy={closePrivacy}/>
      <header className="App-header">
          <img src={logo} className="logo" alt="logo" />
        <div className="bg-container">
          <img src={loading} className="App-logo" alt="logo" />
          <img src={bg} className="background-img" alt="logo" />
        </div>
        <h6 id="ton">
        Ton assistant virtuel pour tes projets yako pesa poto chance !😄
        </h6>
        <h5 className='bientot'>Bientôt disponible. <span style={{color:'#007FFF'}} className="test">Tu veux être le(la) premier(e) à tester ?</span> Tu vas adorer. Promis.</h5>
        <Button sx={{ fontFamily:'Poppins',backgroundColor:'#007FFF', width:{xs:'100%',md:"380px"}}} variant="contained" onClick={openContactForm}>
        <Typography  color={'white'} variant='h7' fontWeight={'400'}>Inscris-toi !</Typography>
        </Button>

      </header>
      <footer>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'end'}}>
          <p>powered by AI</p>
        <p>© <a target="_blank"  rel="noreferrer" href="https://243studio.me">243 Studio</a></p>
        </div>
        <div style={{margin:'0rem 0.5rem 0rem 0.75rem',height:'20px', width:'1px', backgroundColor:'white'}}></div>
        <div className='icon-container'>
          <a href='mailto:poto@243studio.me'>
            <img alt="email icon" className="icon" src={emailIcon}></img>
          </a>
          <a href="https://facebook.com/pesapotochance"
                    target="_blank" rel="noreferrer">
            <img alt="fb icon" className="icon" src={fbIcon}/>
          </a>
        </div>
      </footer>
    </div>
    </SnackbarProvider>
    
  );
}

export default App;
